@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000;
}

.landing-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    color: #fff;
}

#tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    width: 100%;
}

.logo {
    font-family: 'Orbitron', sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(45deg, #fff, #a0a0a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px rgba(255,255,255,0.1);
}

.tagline {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    color: #808080;
    margin-top: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
}

